* {
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: center;
}
* {
    scrollbar-width: thin;
    scrollbar-color: pink #fff0e0;
  }

  .wrapper {
    background: #fff0e0;
    width: 100vw;  
    height: 100vh;
    margin :0;
    display: flex;
    flex-direction: row;

}
 /**************Button **************/

 button {
    border: medium none;
    display: block;
    background: transparent none repeat scroll 0% 0%;
    padding: 0px;
    text-decoration: none;
    color: #286650;
    cursor: pointer;
}

 .check_button {
    font-family: 'Pop'!important;
    font-size: 20px;
    width: 8vw;
    color: #286528;
    background-color: pink;
    border: none;
    border-radius: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.about_button {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: inherit;
    margin-bottom: 1.3rem;
    cursor:  url(./img/pawprint.png), auto;

    &::after {
        content: '';
        position: relative;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) scaleX(0);
        transform-origin: 50% 50%;
        width: 100%;
        height: 1px;
        background-color: #286650;
        transition: transform 250ms;
      }
      
      &:hover {
        
        &::after {
          transform: translateX(-50%) scaleX(1);
        }
        
      }
}


/*************SideBar**********/

.Header__Wrapper {
    color: #286650;
    width:10vw;
    height: 100vh;
    padding: 1rem;

}

.logo-header {
    font-family: 'Pop'!important;
    font-size: 40px;
    width: 30vw;
    color:pink;
}

/***********************About Section***************/

.AboutPopin__Wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    overflow: hidden;
}

.show {
    display: flex;
}
.AboutPopin__Inner {
    width: 80%;
    color: rgb(0, 0, 0);
    font-size: 1rem;
    letter-spacing: 0.015rem;
    background-color: rgba(255, 255, 255, 0.92);
    padding: 6rem 7.5rem;
    border-radius: 5rem;
    text-align: center;
    transform-origin: center top 0px;
    position: relative;
    font-family: 'Laca Light';
}

.CloseButton__Button {
    position: absolute;
    padding: 3rem 4rem;
    top: 0px;
    right: 0px;
    cursor: pointer;
    transform-origin: center center 0px;
    color:#286650;
}
.CloseButton__Button:hover {
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.AboutPopin__Content {
    overflow: auto;
    padding: 0rem 7.5rem;
    max-height: calc(-12rem + 90vh);
}


/*************Search Section********************/
.container {
    display:flex;
    flex-direction:column;
    height: 100vh;
    width:80vw;
}

.search-container {
    background-image: url(./img/bandroleTrans.svg);
    background-position: 50%;
    height: 100%;
    padding: 10px;
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
   
}
.search_greeting {
    text-align: center;
    color :#286650;
    font-size :large;
    font-family: 'Laca Book';

}
.search-icon {
    fill: #3c4043;
    height: 3rem;
    width: 1.5rem;
}



.search-input {
    background-color: #ffffff;
    border: none;
    border-radius: .5rem;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-sizing: border-box;
    color: #202124;
    display: block;
    font-size: 1rem;
    height: 2rem;
    padding: 0 2.5rem 0 1.5rem;
    width: 40%;
    -webkit-appearance: none;
     margin-left :auto;
     margin-right: auto;
}

/***************Plant POP-UP******************/

.plant-container {

    height :100px;
    background-color: rgba(255, 255, 255, 0.92);
    flex-direction: column;
    text-align: center;
    width: 40%;
    overflow-y:auto;
    margin-left :auto;
    margin-right: auto;
    border: none;
    border-radius: .5rem;
    font-family: 'Laca Light';
    display: none;

}

.AboutPlant__Inner {
    width: 40%;
    color: rgb(0, 0, 0);
    font-size: 1rem;
    letter-spacing: 0.015rem;
    background-color: rgba(255, 255, 255, 0.92);
    padding: 3rem 1.5rem;
    border-radius: 5rem;
    text-align: justify;
    transform-origin: center top 0px;
    font-family: 'Laca Light';
    position: relative;
    transition: all 0.5s ease;


}


.AboutPlant {
    width: 80%;
    font-family: 'Laca Light';
    display: flex;
}
.AboutPlant h2 {
    font-family: 'Pop'!important;
    font-size: 40px;
    color:pink;
}

#imgPlant {
    width: 200px;
    height: 300px;
}

.columnDesc{
    display: flex;
    flex-direction: column;
    align-items: center;
}



.logoPet {
    width: 32px;
    height: 32px;
    display: none;
}
.showLogo {
    display: block;
}

#monstera{
    content:url('./img/calathea.svg')
}


/* ****************Footer********************* */
footer {
    height: 10%;
    display: flex;
    justify-content:space-between;
}


.sharing li{
   display: inline;
   text-align: center;
   padding: 2px ;
   margin: 0;
}

#copyright {
    color : #286650;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-right:10px;

}
.copyright{
    display: flex;
    font-family: 'alga, serif';
    font-style: normal;
    font-weight: 200;
}

.modal {
    text-align: center;
    width: auto!important;
    font-size: 5.3rem!important;


}




@media only screen and (max-width: 900px) {
    .AboutPopin__Inner {
        width: 90%;
        padding: 2.5rem;
        border-radius: 2.5rem;
    }
    .CloseButton__Button {
        padding: 1.7rem;
    }
    .AboutPopin__Content {
        padding: 0rem;
        max-height: calc(-17rem + 90vh);
    }
    .modal {
        font-size: 2.3rem!important;

    }
    .AboutPlant__Inner {
        width: 90%;
        padding: 2.5rem;
        border-radius: 2.5rem;
    }
    .AboutPlant h2 {
        font-size: 20px;
    }


    .about_button {
        font-size: 1.1rem;
        font-weight: bold;
        font-family: inherit;
        margin-bottom: 0.5rem;
        cursor:  url(./img/pawprint.png), auto;
    } 
    .wrapper {
        background-image: url('./img/mobileBack.svg');
        background-size: cover;
        background-color: #fff0e0;

        
    }
    .search-container {
        background-image: none;
    }
    .logo-header {
        font-size: 20px;
        margin-top:60px ;

    }
    .search-input {
     width: 100%;
    }
    .plant-container {
     width: 80%;
    }
    #imgPlant {
        width: 140px;
        height: 300px;
    }
    .check_button {
        font-family: 'Pop'!important;
        font-size: 20px;
        width: 20vw;
    }
    #monstera{
        content:url('./img/CalatheaMobile.svg')
    }


}

