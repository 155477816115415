.no-suggestions {
    color: #999;
    padding: 0.5rem;
    width: 40%;
    margin-left :auto;
    margin-right: auto;
  }
  
  .suggestions {
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    background-color: rgba(255, 255, 255, 0.92);
    width: 40%;
    margin-left :auto;
    margin-right: auto;
    border: none;
    border-radius: .5rem;
    font-family: 'Laca Light';

  }


  

.search-input {
    background-color: #ffffff;
    border: none;
    border-radius: .5rem;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-sizing: border-box;
    color: #202124;
    display: block;
    font-size: 1rem;
    height: 2rem;
    padding: 0 2.5rem 0 1.5rem;
    width: 40%;
    -webkit-appearance: none;
     margin-left :auto;
     margin-right: auto;
}
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: pink;
    color: #286650;
    cursor: pointer;
    font-weight: 700;
  }
  
  