
#primary {
  margin: 0;
  padding: 0;
  width: 100vw;
  border:0;
  height: 100vh;

}
/* -------------------- LANDING DESKTOP -------------------- */
/* -------------------- LANDING DESKTOP -------------------- */
/* -------------------- LANDING DESKTOP -------------------- */

#landing-logo {
  font-family: 'Pop', Arial, Helvetica, sans-serif;
  font-size: 6vw;
  cursor:  url(./img/pawprint.png), auto;
  color:#f9c9c5;
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  text-align: center;
  z-index: 10;
  animation: flicker 1.5s infinite alternate;
}
.landing-logo-mobile {
  top: 50vh!important;
  font-size: 14vw;


}
#landing-logo:hover {
  color: pink;

}

@keyframes flicker {

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
     text-shadow:
        -0.2rem -0.2rem 1rem #fff,
        0.2rem 0.2rem 1rem #fff,
        0 0 2rem #39ff14,
        0 0 4rem#39ff14,
        0 0 6rem #39ff14,
        0 0 8rem #39ff14;
  }

  20%,
  24%,
  55% {
     text-shadow: none;
  }
}


.rainbow-container {
  
  background-image: url(./img/nature.png);
  background-repeat:no-repeat;
  background-size:cover;
  height: 100vh;
  width: 100vw;
}

.rainbow-container-mobile {
  background-image: url(./img/natureRotate.png);
  background-repeat:no-repeat;
  background-size:cover;
  height: 100vh;
  width: 100vw;
}





@media only screen and (min-width: 768px) {
  .rainbow-container {
    display: block !important;
  }

  .rainbow-container-mobile {
    display: none;
  }
}

 .vanish {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}


@media only screen and (max-width: 900px) {

  #landing-logo {
    top: 40vh!important;
    font-size: 14vw;
    text-align: center;
  
  
  }
}